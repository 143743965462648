import type { Product, ProductVariant } from '~/types/models/product'

export function useBuyWithPrimeWidget() {
  const {
    public: { BwpScriptUrl },
  } = useRuntimeConfig()

  function onLoaded() {}

  const { load, unload } = useScriptTag(BwpScriptUrl, onLoaded, { async: true, manual: true })

  const loadScript = () => {
    load()
  }

  const unloadScript = async () => {
    unload()
  }

  const updateBwpVariant = (variant: ProductVariant, isQuickShop: boolean, product: Product) => {
    const storefrontStore = useStorefrontStore()
    const { currentStorefrontCode } = storeToRefs(storefrontStore)

    if (currentStorefrontCode.value === 'us'
      && !isQuickShop
      && product.categories.includes('buy-with-prime')) {
      window.bwp.updateWidget(variant.sku)
    }
  }

  return {
    loadScript,
    unloadScript,
    updateBwpVariant,
  }
}
