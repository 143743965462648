<script lang="ts" setup>
const props = defineProps<{
  productSku: string | undefined
  onForceSelection: () => void

}>()

const {
  public: { BwpSiteId, BwpWidgetId,
  },
} = useRuntimeConfig()
</script>

<template>
  <ProductBuyWithPrimePlaceholder
    v-if="!props.productSku"
    :on-force-selection="onForceSelection"
  />
  <div
    id="amzn-buy-now"
    :data-site-id="BwpSiteId"
    :data-widget-id="BwpWidgetId"
    :data-sku="props.productSku"
  />
</template>

<style scoped>
</style>
